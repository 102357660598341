import React from "react";

import VideoPlayer from "../video/video.component";

import { Container } from "./short-promotion-video.styles";

const ShortPromotionVideo = () => {
	return (
		<Container>
			<VideoPlayer
				//16: 9 video format
				srcVideo={
					"https://player.vimeo.com/external/1010371568.m3u8?s=2ccc21fd43f11d94109152d4be93289c4040c493&logging=false"
				}
				autoPlay={true}
				aspectRatio={[9, 16]}
				controls={false}
				transitionDuration={0}
				muted={true}
				loop={true}
			></VideoPlayer>
		</Container>
	);
};

export default ShortPromotionVideo;
