import React from "react";
import "./toggle.styles.css";

const Toggle = ({ setBillingCycle, billingCycle }) => {
	return (
		<label className="switch">
			<input
				type="checkbox"
				checked={billingCycle === "annual"}
				onChange={event => {
					setBillingCycle(
						event.target.checked ? "annual" : "monthly"
					);
				}}
			/>
			<span className="slider" />
		</label>
	);
};

export default Toggle;
